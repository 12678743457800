import React from 'react';
import TextToggle from '../components/text-toggle/text-toggle';
import WoodHome from '../images/wood-home-ready.png';
import { Container, Row, Col } from 'reactstrap';
import Layout from "../components/layout";
import SEO from "../components/seo";
import TopBunerProduct from '../components/top-buner/top-baner-product';
import OfficeAtMap from '../components/office-on-map/office-on-map';
import ImagesView from '../components/images-view/images-view';
import Advantages from '../components/advantages/advantages';
import FormQuickOrder from '../components/form-quick-oreder/form-quick-order';

import PriceList from '../components/price-list/price-list';


const PokraskaDomaIzSruba = (props) => {
  const breadCramb = [{name:'Главная', url:'/', status: false}, 
    {name:'Покраска сруба из бруса', url:'#', status:true}]
  return (
    <div>
        <SEO title="Герметизация деревянных домов в Калининградской области"
            description="Опытные бригады в срок выполнят работы по герметизации деревянных домов и срубов в Калининграде.">                          
      </SEO>
      <Layout>
      <TopBunerProduct TopImage={''}  
                      titileH1={'Герметизация деревянных домов в Калинингра&shy;дской области'} 
                      comment={""}
                      FormView={true}
                      LogoView={false}
                      typeofBunner={''}
                      breadCrumbs={breadCramb}>
            </TopBunerProduct>
            <Container fluid>
        <Row className="mt-5">
              <Col md="6" xs='12' className="text-justify">
                <h1 className="text-center text-md-left">Герметизация деревянных домов</h1>
                <p>
                Герметизация деревянных домов – это технология, позволяющая повысить комфортность бревенчатых зданий.
                 Независимо от способа обработки оцилиндрованный, профилированный или клееный брус со временем начинает покрываться трещинами.
                  Глубина щелей может достигать половины толщины стены. Внутри постепенно накапливается влага,
                   образуются колонии плесени и насекомых. Чтобы защитить жилой коттедж, дачу или баню,
                    потребуется специальная обработка древесины.</p>
                <Advantages />
              </Col>
              <Col md="6" xs='12' className="text-center">
                <img src={WoodHome} className="w-100" style={{borderRadius:"0px 0px 150px 14px"}}  alt="" title="" />
              </Col>
            </Row>
        </Container>
        <Container fluid className="">
          <Container className="mt-2 p-1 p-md-3">
            <Row>
              <h2>Цены на работы по наружной окаске сруба</h2>
             
            </Row>
          </Container>
         </Container>
        <FormQuickOrder className="mt-5" />
        <ImagesView />
        <OfficeAtMap />
      </Layout>
      
    </div>
  );
};

export default PokraskaDomaIzSruba;